<template>

  <div>
    <component :is="data.subtype">{{ data.label }}</component>
  </div>

</template>

<script>

export default {

  props: { data: Object }

}

</script>

<style lang="sass" scoped>
  @import '@/components/formBuilder/style/variables.sass'
  @import '@/components/formBuilder/style/form/paragraph.sass'
</style>
